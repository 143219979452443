import React from 'react';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV4 from './components/home-v4';
import Property from './components/property';
import PropertyDetails from './components/property-details';
import Error from './components/error';
import TourPopup from './components/TourPopup';
import Contact from './components/contact-us';

function ThemeForest() {
    return(
        <Router>
            <HashRouter basename="/">
            <div>
            <Switch>
                <Route exact path="/" component={HomeV4} />
                <Route path="/units" component={Property} />
                <Route 
                    path="/unit-details" 
                    component={PropertyDetails} 
                />
                <Route path="/error" component={Error} />
                <Route path="/contact" component={Contact} />
            </Switch>
            </div>
            </HashRouter>
            <TourPopup />
        </Router>
    )
}

export default ThemeForest;

import React, { useState, useEffect } from 'react';
import { MICROSITE_INFO } from './utils/constants';
import { locationGql, unitsGql, unitTypeGql, orgDetailsGql, amenitiesGql, toursGql, tourTypesGql } from './store/schemas/location';
import { getClient } from './init-apollo-googleFn';

const Context = React.createContext();

function ContextProvider({ children }) {
  const [amenitiesObj, setAmenitiesObj] = useState(null); // needed in multiple places?
  const [location, setLocation] = useState(null); // needed in multiple places?
  const [locationId, setLocationId] = useState(null); // only needed in MainContent
  const [orgId, setOrgId] = useState(null); // needed to set above.
  const [template, setTemplate] = useState(""); // needed only in main content.
  const [tours, setTours] = useState("")
  const [tourTypes, setTourTypes] = useState("");
  const [units, setUnits] = useState(null); // needed in MainContent only
  const [unitType, setUnitType] = useState(null); // needed in multiple places?
  const [updatedUnits, setUpdatedUnits] = useState([]); // needed in multiple places
  const [fromDate, setFromDate] = useState("");

  useEffect(() => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    
    today = mm + '/' + dd + '/' + yyyy;

    setFromDate(today);
  }, [])

  const micrositeClient = getClient(MICROSITE_INFO);

  const getLocations = (locationId) => {
    try {
      micrositeClient.query({
        query: locationGql,
        variables: {
          locationId: locationId
        }
      }).then(response => {
        setLocation(response.data.location.edges[0].node);
        setOrgId(response.data.location.edges[0].node.organizationId);
        setTemplate(response.data.location.edges[0].node.templateId);
        const org = response.data.location.edges[0].node.organizationId
        getOrgDetails(org)
      }).catch(e => console.log(e));
    }
    catch(e) {
      console.log(e);
    }
  }

  const getAmenities = () => {
    try {
      micrositeClient.query({
        query: amenitiesGql,
      }).then(response => {
        setAmenitiesObj(response.data.amenities.edges);
      }).catch(e => console.log(e));
    }
    catch(e) {
      console.log(e);
    }
  }

  const getOrgDetails = (org) => {
    try {
      micrositeClient.query({
        query: orgDetailsGql,
        variables: {
          orgId: org
        }
      }).then(response => {
        localStorage.setItem("orgDetails", JSON.stringify(response.data.org.edges[0].node));
      }).catch(e => console.log(e));
    }
    catch(e) {
      console.log(e);
    }
  }

  const getUnitType = (locationId) => {
    try {
      micrositeClient.query({
        query: unitTypeGql,
        variables: {
          locationId: locationId
        }
      }).then(response => {
        setUnitType(response.data.unitType.edges);
      }).catch(e => console.log(e));
    }
    catch(e) {
      console.log(e);
    }
  }

  const getUnits = (locationId) => {
    try {
      micrositeClient.query({
        query: unitsGql,
        variables: {
          locationId: locationId
        }
      }).then(response => {
        setUnits(response.data.units.edges);
      }).catch(e => console.log(e));
    }
    catch(e) {
      console.log(e);
    }
  }

  const getTours = (locationId) => {
    try {
      micrositeClient.query({
        query: toursGql,
        variables: {
          location: locationId,
        }
      }).then(response => {
        setTours(response.data.tours.edges);
      }).catch(e => console.log(e));
    }
    catch(e) {
      console.log(e);
    }
  }
  const getTourTypes = (locationId) => {
    try {
      micrositeClient.query({
        query: tourTypesGql,
        variables: {
          location: locationId,
        }
      }).then(response => {
        setTourTypes(response.data.tourTypes.edges.filter(t => t.node.selfBooking));
      }).catch(e => console.log(e));
    }
    catch(e) {
      console.log(e);
    }
  }

  return(
    <Context.Provider value={{
      amenitiesObj,
      location, 
        setLocation,
      locationId, 
        setLocationId,
      orgId,
      fromDate,
      template,
        setTemplate,
      tours,
      tourTypes,
      unitType,
        setUnitType,
      units, 
        setUnits,
      updatedUnits,
        setUpdatedUnits,
        // findUnitAmenities, // function
      getAmenities,
      getOrgDetails,
      getTours,
      getTourTypes,
      getUnitType, // func
      getUnits, // func
      getLocations // func
    }}>
      {children}
    </Context.Provider>
  )
};

export { Context, ContextProvider };
import React, { useContext } from 'react';
import placeholderPhoto from '../../assets/img/bg/1.png'
import { Context } from '../../../../../AppContext';

function Calltoaction() {
  const { location } = useContext(Context);
  const photosArray = location.photos !== [] ? location.photos.slice(2, location.photos.length - 2) : placeholderPhoto;
  const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
  const inlineStyle = {
    backgroundImage: `url(${photosArray})`
  }

  return (
    <div className="call-to-action-area">
      <div className="container">
        <div className="call-to-action style-two" style={ inlineStyle }>
          <div className="cta-content">
            <h3 className="title">Contact With Our Agent</h3>
            <a className="btn btn-white mb-2 mb-sm-0 mr-2" href={`tel:${orgDetails.phone}`}>Make a Call</a>
            <a className="btn btn-white mb-2 mb-sm-0 mr-xl-3 mr-0" href={`mailto:${orgDetails.businessEmail}`}>Email Us</a>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Calltoaction
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../../AppContext';
import '../../../../sass/style.scss';
import { leadSchema, tourLeadSchema } from '../../../../../Validations/UserValidations';
import { MICROSITE_INFO } from '../../../../../utils/constants';
import { getClient } from '../../../../../init-apollo-googleFn';
import { createTourGql, createLeadGql } from '../../../../../store/schemas/location';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { TextField } from './TextField';
import { CustomDropDown } from './CustomDropDown';
import 'react-toastify/dist/ReactToastify.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

export default function TourForm() {
  /* eslint-disable */
  const { updatedUnits, locationId, tourTypes, } = useContext(Context);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isTourReady, setIsTourReady] = useState(false);
  const [leadId, setLeadId] = useState("");
	const orderedUnits = [...updatedUnits].sort((a, b) => a.node.number - b.node.number);
  const [leadStatus, setLeadStatus] = useState(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [isStep, setIsStep] = useState("open");
  const [calendarTouched, setCalendarTouched] = useState(false);
  const [tourValues, setTourValues] = useState(null);
  /* eslint-enable */

  const micrositeClient = getClient(MICROSITE_INFO);

  const createTour = (obj) => {
    try {
      micrositeClient.mutate({
        mutation: createTourGql,
        variables: {
          input: {
            leadId: leadId,
            name: obj.firstName + " " + obj.lastName,
            tourType: obj.tourType,
            date: selectedDate,
            time: obj.timePreference,
            location: locationId,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
          }
        }
      }).then(response => {
        setIsTourReady(false);
        setToastMessage(toast.success(response.data.createTour.response));
      }).catch(e => {
        setToastMessage(toast.warning("We were unable to schedule your tour, but we have your contact information and will reach out to you. Our apologies."));
        console.log(e)
        setIsTourReady(false);
      });
    }
    catch(e) {
      if (leadStatus === "Success") {
        setToastMessage(toast.warning("We were unable to schedule your tour, but we have your contact information and will reach out to you. Our apologies."));
      } else {
        setToastMessage(toast.error("We were unable to retrieve your data, our apologies."));
      }
      console.log(e)
    }
  }

  useEffect(() => {
    return toastMessage;
  }, [toastMessage])

  const createLead = (obj) => {
    try {
      micrositeClient.mutate({
        mutation: createLeadGql,
        variables: {
          input: {
            email: obj.email,
            lastName: obj.lastName,
            firstName: obj.firstName,
            status: "Tour",
            source: "Microsite",
            phoneMobile: obj.phoneNumber,
            location: locationId,
            preference: {
              unit: obj.unit,
            } 
          }
        }
      }).then(response => {
        setLeadId(response.data.createLead.lead.id);
        setIsTourReady(true);
        setSelectedDate("");
				if (response.data.createLead.response === null && response.data.createLead.lead.id) {
          setLeadStatus("Success");
				} else if (response.data.createLead.response !== null && response.data.createLead.lead.id) {
          setLeadStatus("Exists");
				} else {
          setLeadStatus("Failed")
				}
      }).catch(e =>{
        console.log(e)});
    }
    catch(e) {
      console.log(e);
    }
  }

  /* eslint-disable */
  useEffect(() => {
		// findSelectedUnit();
	}, [selectedUnit]);
  /* eslint-enable */

  function CustomOverlay({ classNames, selectedDay, children, ...props }) {
    return (
      <div
        className="day-picker-popup"
        style={{ marginTop: -369, marginLeft:-42 }}
        {...props}
      >
        <div className={classNames.overlay}>
          {children}
        </div>
      </div>
    );
  }

  /* eslint-disable */
  const tourLeadSubmit  = async(values) => {
    const isValid = await leadSchema.isValid();
    setTourValues(values);
    createLead(values);
  }

  useEffect(()=> {
    if (isTourReady) createTour(tourValues)
  }, [isTourReady]);
  /* eslint-enable */

  return(
      <div 
        className={"tour-form-container"}
        style={{
          bottom: "350px",
          height: isStep === "open" ? "250px" : (isStep === "form") ? "550px" : "550px"
        }}
      >
        <ToastContainer position="bottom-center"/>
      {
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            unit: "",
            tourType: "",
            tourAgent: "",
            timePreference: ""
          }}
          validationSchema={tourLeadSchema}
          onSubmit={(values, {resetForm}) => {
            tourLeadSubmit(values);
            resetForm();
          }}
        >
          {formik => (
            <Form id="tour-form" className="contact-form-wrap" style={{background: "#F6F7FB"}}>
              <h4>Schedule a tour</h4>
              <div className="row">
                <div className="col-sm-6">
                  <TextField label="First Name" name="firstName" type="text" />
                </div>
                <div className="col-sm-6">
                  <TextField label="Last Name" name="lastName" type="text" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <TextField label="Email" name="email" type="email" />
                </div>
                <div className="col-sm-6">
                  <TextField label="Phone Number" name="phoneNumber" type="text" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <CustomDropDown label="Tour Type" name="tourType" data="tourType" obj={tourTypes} text="Tour Type:"/>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <CustomDropDown label="Unit" name="unit" data="orderedUnits" obj={orderedUnits} text="Choose a unit to tour:"/>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <CustomDropDown label="Time Preference" name="timePreference" obj={["Morning", "Afternoon", "Evening"]} text="Choose a time:" />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <label for="units" className="rld-single-input">Suggest a date:</label>
                </div>
                <div className="col-sm-6">
                  <div style={{ 
                      marginTop:"10px", 
                    }} 
                    name="dateTime"
                    onClick={() => setCalendarTouched(true)}
                    className={`${selectedDate === "" && calendarTouched && "form-error-input"}`}
                  >
                    <DayPickerInput
                      className="day-picker-input"
                      overlayComponent={CustomOverlay}
                      placeholder="DD/MM/YYYY"
                      style={{background:"#f3f3f3"}}
                      value={selectedDate}
                      onDayChange={day => setSelectedDate(new Date(day).toLocaleDateString("en-US"))} 
                      dayPickerProps={{ disabledDays: {before: new Date()} }}
                    />
                  </div>
                </div>
              </div>
              {
                (selectedDate === "" && calendarTouched) &&
                <div
                  className="form-error-message"
                  style={{textAlign: "right"}}        
                >
                  Please select a date
                </div>
              }
              <div className="btn-wrap text-center">
                <button className="btn btn-yellow" type="click">Submit</button>
              </div>
            </Form>
          )}
        </Formik>
      }
    </div>
  );
}
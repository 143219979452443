import React, { useContext } from 'react';
import { Context } from '../../../../../AppContext';
import { Link } from 'react-router-dom';
import placeholderPhoto from '../../assets/img/banner/header.jpg';

function Page_header(props) {
  const { header, subheader, } = props;
  const { location } = useContext(Context);
  const photosArray = location.photos === "[]" ? placeholderPhoto : location.photos.slice(2, location.photos.length - 2);
  const inlineStyle = {
      backgroundImage: `url(${photosArray})`
  }
  return (
    <>
      {
        <div className="breadcrumb-area jarallax" style= { inlineStyle }>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcrumb-inner">
                  <h1 className="page-title">{header}</h1>
                  <ul className="page-list">
                    <li><Link to="/">Home</Link></li>
                    <li>{subheader}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}


export default Page_header
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Context } from '../../../../../AppContext';
import placeholderPhoto from '../../assets/img/banner/header.jpg';

function BannerV4 ()  {
  const { location } = useContext(Context);
  const headerPhoto = location.photos === "[]" ? placeholderPhoto : JSON.parse(location.photos);
  const inlineStyle = {
    backgroundImage: `url(${headerPhoto[0]})`
  }

  return (
    <div>
      <div className="banner-area jarallax" style={ inlineStyle }>
        <div className="container">
          <div className="banner-inner-wrap">
            <div className="row">
              <div className="col-lg-10">
                <div className="banner-inner">
                  <h1 className="title welcome">Welcome to {location.customId}</h1>
                  <div className="banner-btn-wrap">
                    <Link 
                      className="btn btn-yellow mr-2" 
                      to="/contact" 
                      onClick={()=> {
                        window.scrollTo(0,0);
                      }}  
                    >
                      GET APPOINTMENT
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerV4
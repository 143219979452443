import React from 'react';
import { ErrorMessage, useField } from 'formik';
import placeholder from '../../assets/img/undraw/undraw_apartment.png';

export const UnitDropDown = ({ label, orderedUnits, ...props }) => {
  const [field, meta] = useField(props);

  /* eslint-disable */
  const renderDropdown = () => orderedUnits.map((u, i) => {
    if(u.node.status === "vacant") {
      return <option key={`${i}${u}`}value={u.node.id}>Unit {u.node.number}: {u.node.unitType.type}</option>
    }
  });
  /* eslint-enable */

  const findSelectedUnit = unit => {
    const match = orderedUnits.find(u => u.node.number === unit);
    if(match) {
      return match.node.photos[0] === undefined ? placeholder : match.node.photos[0];
    }
  }

  return (
    <div className="has-validation">
      <div className="row">
        <div className="col-sm-6">
          <label htmlFor="units" className="rld-single-input">Select a unit to discuss:</label>
        </div>
        <div className="col-sm-6">
          <select
            {...field}
            {...props}
            className={`rld-single-input form-control ${meta.touched && meta.error && "form-error-input"}`}
            name="unit"
            id="unit"
            style={{background: "#F3F3F3"}}
        >
          <option value="" default></option>
          {renderDropdown()}
        </select>
        </div>
      </div>
      {<img style={{paddingTop: "20px"}} alt='' src={findSelectedUnit(field.value)}/>}
      <ErrorMessage
        component="div"
        name={field.name}
        className="form-error-messsage"
        style={{textAlign: "center"}}
      />
    </div>
  )
}
import React, { useEffect, useState, useContext } from 'react';
import Error from './error';
import { Context } from "./AppContext";
import PageLoader from './PageLoader';

import './App.css';
import './Templates/sass/style.scss'
import ThemeForest from './Templates/Apartment/ThemeForest/ThemeForest'; // ThemeForest in dropdown menu - id = 1

function MainContent() {
  const {
    amenitiesObj,
    location,
      setLocationId,
    template,
    units,
    unitType,
      setUpdatedUnits,
    getAmenities,
    getLocations,
    getTours,
    getTourTypes,
    getUnits,
    getUnitType
  } = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);

  /* eslint-disable */
  useEffect(() => {
    setLocationId(window.location.pathname.split("/")[2]);
    const location =window.location.pathname.split("/")[2]
    if(location ){
      getLocations(location)
      getUnitType(location)
      getUnits(location)
      getAmenities()
      getTours(location)
      getTourTypes(location)
    }
  }, []);
  /* eslint-enable */

  const checkAmenities = (amenityId) => {
    const amenity = amenitiesObj.find(a => a.node.id === amenityId);
    return amenity&& amenity.node.name;
  }

  // Checks query returns
  const checkLoader = () => {
    if(
      location !== null &&
      units !== null &&
      unitType !== null &&
      amenitiesObj !== null &&
      JSON.parse(localStorage.getItem("orgDetails")) !== undefined
    ) {
      if(units !== null) {
        units.forEach(u => {
          let match = unitType.filter(id => (id.node.locationId === u.node.locationId) && (id.node.sqft === u.node.sqft));
          const amenities = u.node.amenities !== null ? u.node.amenities.map(a => checkAmenities(a)) : "";
          const floorPlan = match[0];
          const updated = {...u,  floorPlan, amenities};
          setUpdatedUnits(prevState => [...prevState, updated].slice(0, units.length));
        });
        setIsLoading(false);
        document.title = location.name;
      }
    }
  };

  const renderTemplate = () => {
    switch(template) {
      case 1:
        return <ThemeForest />
      default:
        return <Error />
    } 
  };

  /* eslint-disable */
  useEffect(() => {
    checkLoader();
  }, [units, location, unitType, amenitiesObj, isLoading]);
  /* eslint-enable */

  return (
    <>
      {
        isLoading ?
        <PageLoader/>
        :
        renderTemplate()
      }
    </>
  );
}

export default MainContent;
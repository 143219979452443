import React, { useContext, useState } from 'react';
import { Context } from '../../../../../AppContext';

function AboutV2()  {
  const { location, amenitiesObj } = useContext(Context);
  const [scrollActive, setScrollActive] = useState(false);
  
  const amenitiesArray = location.amenities.map(element => {
    return amenitiesObj.filter(amenitiesObj => amenitiesObj.node.dbId === element);
  });

  const miniArray = amenitiesArray.slice(0, 9);

  return (
    <div className="about-area bg-gray pd-top-100 pd-bottom-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 align-self-center">
            <div className="section-title mb-lg-0">
              <h2 className="title" dangerouslySetInnerHTML={{__html: `About ${location.customId}`}}></h2>
              <p>{ location.description }</p>
            </div>
          </div>
          <div className="col-lg-7"
          >
            {/* building/community amenities */}
            <div className="row amenities-overflow scroll">
              {!scrollActive && miniArray.map( ( item, i ) =>
                <div key={ i } className="col-md-4 col-sm-6">
                  <div className="cat-single text-center">
                    <i className="fa fa-check" />
                    <p> {item[0].node.name} </p>
                    {/* <p>Placeholder</p> */}
                  </div>
                </div>
              ) }
              {amenitiesArray.length > 9 &&
                <button className="btn btn-yellow mr-2 amenities-btn" onClick={()=>setScrollActive(!scrollActive)}>
                  {scrollActive ? "Collapse": "View All"}
                </button>
              }
              {scrollActive && 
                amenitiesArray.map( ( item, i ) =>
                  <div key={ i } className="col-md-4 col-sm-6 ">
                    <div className="cat-single text-center">
                      <i className="fa fa-check" />
                      <p> {item[0].node.name} </p>
                      {/* <p>Placeholder</p> */}
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutV2
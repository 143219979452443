import * as Yup from 'yup';

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const leadSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .required('First Name is required')
    .trim(),
  lastName: Yup.string()
    .min(1, 'lease enter your last name')
    .max(30, 'Must be 30 characters or less')
    .required('Last Name is required')
    .trim(),
  email: Yup.string().email().required('Valid Email is required').trim(),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone Number is required'),
  unit:Yup.string().required('Unit preference is required').trim(),
});

export const tourSchema = Yup.object().shape({
  tourType: Yup.string().required('Tour type is required').trim(),
  timePreference: Yup.string().required('Time preference is required').trim(),
});

export const tourLeadSchema = tourSchema.concat(leadSchema);
import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyDetailsSection from './section-components/property-details';
import Footer from './global-components/footer-v2';

const PropertyDetails = () => {
    return <div>
        <Navbar />
        <PageHeader header={JSON.parse(localStorage.getItem("selectedUnit")).floorPlan.node.type} subheader="Units/Unit-Details" />
        <PropertyDetailsSection />
        <Footer />
    </div>
}

export default PropertyDetails;
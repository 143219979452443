import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../../AppContext';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Footer from './global-components/footer-v2';
import MapContainer from './section-components/google-map';
import { leadSchema } from '../../../../Validations/UserValidations';
import { MICROSITE_INFO } from '../../../../utils/constants';
import { createLeadGql } from '../../../../store/schemas/location';
import { getClient } from '../../../../init-apollo-googleFn';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { TextField } from './section-components/TextField';
import { UnitDropDown } from './section-components/UnitDropDown';
import PageLoader from '../../../../PageLoader';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
	const { location, updatedUnits, locationId } = useContext(Context);
	const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
	const [isLoading, setIsLoading] = useState(true);
	const [info, setInfo] = useState({})
	const [phoneNumber, setPhoneNumber] = useState("");
	const [toastMessage, setToastMessage] = useState(null);

	/* eslint-disable */
	useEffect(() => {
		orgDetails.address !== undefined && setIsLoading(false);
	}, [orgDetails]);

	useEffect(() => {
		if(!isLoading) {
			setInfo(JSON.parse(orgDetails.address));
			setPhoneNumber(`+${orgDetails.phone[0]} (${orgDetails.phone.slice(1, 4)}) ${orgDetails.phone.slice(4, 7)} ${orgDetails.phone.slice(7)}`);
		}
	}, [isLoading]);
	/* eslint-enable */

	const orderedUnits = [...updatedUnits].sort((a, b) => a.node.number - b.node.number);

	const micrositeClient = getClient(MICROSITE_INFO);

  const createLead = (locationId, obj) => {
    try {
      micrositeClient.mutate({
        mutation: createLeadGql,
        variables: {
          input: {
            email: obj.email,
            lastName: obj.lastName,
            firstName: obj.firstName,
            status: "Prospect",
            source: "Microsite",
            phoneMobile: obj.phoneNumber,
            location: locationId,
            preference: {
              unit: obj.unit,
            }
          }
        }
      }).then(response => {
				if (response.data.createLead.response === null && response.data.createLead.lead.id) {
					setToastMessage(toast.success("Success!"));
				} else if (response.data.createLead.response !== null && response.data.createLead.lead.id) {
					setToastMessage(toast.warn("This email is already associated with this unit."));
				} else {
					setToastMessage(toast.error("We were unable to process your request. We may already have your email associated with the selected unit."));
				}
      }).catch(e => console.log(e));
    }
    catch(e) {
      console.log(e);
    }
  }


	/* eslint-disable */
	useEffect(() => {
		return toastMessage;
	}, [toastMessage]);

  const leadFormSubmit = async(values) => {
    const isValid = await leadSchema.isValid();
		createLead(locationId, values);
  }
	/* eslint-enable */

	return (
		<>
			{
				isLoading
				?
				<PageLoader />
				:
				<>
				<Navbar />
				<PageHeader header="Contact" subheader="Contact"/>
				<div className="contact-area pd-top-100">
					<ToastContainer position="bottom-center"/>
					<div className="container">
						<div className="row">
							<div className="col-lg-8">
								<div className="contact-page-map">
									<MapContainer lat={location.lat} lng={location.lng} name={location.name} width="100" height="100"/>
								</div>
							</div>
							<div className="col-lg-4">
							<Formik
								initialValues={{
									firstName: "",
									lastName: "",
									email: "",
									phoneNumber: "",
									unit: ""
								}}
								validationSchema={leadSchema}
								onSubmit={(values, {resetForm}) => {
									leadFormSubmit(values);
									resetForm();
								}}
							>
								{formik => (
									<div>
										<Form id="contact" className="contact-form-wrap contact-form-bg">
											<h4> Contact Us</h4>
											<TextField label="First Name" name="firstName" type="text" />
											<TextField label="Last Name" name="lastName" type="text" />
											<TextField label="Email" name="email" type="email" />
											<TextField label="Phone Number" name="phoneNumber" type="text" />
											<UnitDropDown label="Unit" name="unit" orderedUnits={orderedUnits} />
											<div className="btn-wrap text-center">
												<button className="btn btn-yellow" type="click">Submit</button>
											</div>
										</Form>
									</div>
								)}
							</Formik>
							</div>
						</div>
						<div className="row pd-top-92">
							<div className="col-xl-4 col-sm-6">
								<div className="single-contact-info">
									<p><i className="fa fa-phone" />Call Us:</p>
									<h5><a href={`tel:${orgDetails.phone}`}>{phoneNumber}</a></h5>
								</div>
							</div>
							<div className="col-xl-4 col-sm-6">
								<div className="single-contact-info">
									<p><i className="fa fa-envelope" />Have any Question?</p>
									<h5><a href={`mailto:${orgDetails.businessEmail}`}>{orgDetails.businessEmail}</a></h5>
								</div>
							</div>
							<div className="col-xl-4 col-sm-6">
								<div className="single-contact-info">
									<p><i className="fa fa-phone" />Address</p>
									<h5>{info.street_one}, {info.city},</h5>
									<h5>{info.state} {info.zip}, {info.country}</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
				</>
			}
		</>
	);
}

export default Contact;

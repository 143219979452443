import React, { useContext, useEffect, useState } from 'react';
import sqftIcon from '../../assets/img/icons/7.png';
import { Context } from '../../../../../AppContext';
import placeholderUnitPic from '../../assets/img/undraw/undraw_apartment.png';
import MapContainer from './google-map';
import { leadSchema } from '../../../../../Validations/UserValidations';
import { MICROSITE_INFO } from '../../../../../utils/constants';
import { createLeadGql } from '../../../../../store/schemas/location';
import { getClient } from '../../../../../init-apollo-googleFn';
import { ToastContainer ,toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { TextField } from './TextField';
import 'react-toastify/dist/ReactToastify.css';
import placeHolderPlanPic from '../../assets/img/undraw/undraw_plan.png';

function PropertyDetails() {
  
  const { location, locationId } = useContext(Context);
  const selectedUnit = JSON.parse(localStorage.getItem("selectedUnit"));
  const photos = selectedUnit.node.photos;
  const orgDetails = JSON.parse(localStorage.getItem("orgDetails"));
  const address = JSON.parse(orgDetails.address);
  const [mutateReady, setMutateReady] = useState(false);
  const floorPlanPic = selectedUnit.floorPlan.node.photos !== "[]" ? selectedUnit.floorPlan.node.photos.slice(4, selectedUnit.floorPlan.node.photos.length -4) : placeHolderPlanPic;
  const [toastMessage, setToastMessage] = useState(null);

	const micrositeClient = getClient(MICROSITE_INFO);

  const createLead = (locationId, obj) => {
    try {
      micrositeClient.mutate({
        mutation: createLeadGql,
        variables: {
          input: {
            email: obj.email,
            lastName: obj.lastName,
            firstName: obj.firstName,
            status: "Prospect",
            source: "Microsite",
            phoneMobile: obj.phoneNumber,
            location: locationId,
            preference: {
              unit: selectedUnit.node.id,
            }
          }
        }
      }).then(response => {
				if (response.data.createLead.response === null && response.data.createLead.lead.id) {
					setToastMessage(toast.success("Success!"));
				} else if (response.data.createLead.response !== null && response.data.createLead.lead.id) {
					setToastMessage(toast.warn("This email is already associated with this unit."));
				} else {
					setToastMessage(toast.error("We were unable to process your request. We may already have your email associated with the selected unit."));
				}
      }).catch(e => console.log(e));
    }
    catch(e) {
      console.log(e);
    }
  }

  /* eslint-disable */
  useEffect(() => {
    if(mutateReady) {
      createLead(locationId);
      setMutateReady(false);
    }
  }, [mutateReady])

  useEffect(() => {
		return toastMessage;
	}, [toastMessage]);

  const leadFormSubmit = async(values) => {
    const isValid = await leadSchema.isValid();
		createLead(locationId, values);
  }
  /* eslint-enable */

  return(
    <>
      <div className="property-details-area" id="test">
        <ToastContainer position="bottom-center"/>
        <div className="bg-gray pd-top-100 pd-bottom-60">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-8">
                  { photos.length > 1 ?
                  <div className="property-details-slider">
                    {photos.map((pic, i) => 
                    <div key={i} className="item">
                      <div className="thumb">
                        <img src={pic} height="643.5px" width="990px" alt={`Apartment (${i} of ${photos.length})`}/>
                      </div>
                    </div>
                    )}
                  </div>
                  :
                    <div className="item">
                      <div className="thumb">
                        <img
                          src={photos.length === 1 ? photos[0] : placeholderUnitPic}
                          style={{
                            height: "643.5px",
                            width: "990px",
                            borderRadius: "6px",
                            paddingBottom: "12px",
                            marginBottom: "20px"
                          }}
                          alt="apartment"/>
                      </div>
                    </div>
                  }
                <div className="property-details-slider-info mb-5">
                  <h3 style={{fontSize: "22px"}}>Unit: {selectedUnit.node.number} <span style={{position: "absolute", right: "0px"}}>${selectedUnit.node.price}/month</span></h3>
                </div>
              </div>
              
              <div className="col-xl-3 col-lg-4">
                <div className="widget widget-owner-info mt-lg-0 mt-5 bg-gray">
                <Formik
								initialValues={{
									firstName: "",
									lastName: "",
									email: "",
									phoneNumber: "",
									unit: selectedUnit.node.number
								}}
								validationSchema={leadSchema}
								onSubmit={(values, {resetForm}) => {
									leadFormSubmit(values);
									resetForm();
								}}
							>
								{formik => (
									<div>
										<Form id="contact" className="contact-form-wrap contact-form-bg">
											<h4> Contact Us</h4>
											<TextField label="First Name" name="firstName" type="text" />
											<TextField label="Last Name" name="lastName" type="text" />
											<TextField label="Email" name="email" type="email" />
											<TextField label="Phone Number" name="phoneNumber" type="text" />
											<div className="btn-wrap text-center">
												<button className="btn btn-yellow" type="click">Submit</button>
											</div>
										</Form>
									</div>
								)}
							</Formik>
                  <div className="contact-info">
                    <h5 className="mb-4">Contact Info</h5>
                    <div className="media">
                      <div className="media-left">
                        <i class="fa fa-map-marker"></i>
                      </div>
                      <div className="media-body">
                        <p>Address</p>
                        <span>{`${address.street_one}, ${address.city}, ${address.state} ${address.zip}`}</span>
                      </div>
                    </div>
                    <div className="media">
                      <div className="media-left">
                        <i className="fa fa-phone" />
                      </div>
                      <div className="media-body">
                        <p>Phone</p>
                        <span>{`+${orgDetails.phone[0]} (${orgDetails.phone.slice(1, 4)}) ${orgDetails.phone.slice(4, 7)} ${orgDetails.phone.slice(7)}`}</span>
                      </div>
                    </div>
                    <div className="media mb-0">
                      <div className="media-left">
                        <i className="fa fa-envelope" />
                      </div>
                      <div className="media-body">
                        <p>Email</p>
                        <span>{orgDetails.businessEmail}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row pd-top-90">
            <div className="col-lg-9">
              <div className="property-info border-bottom-yellow padding-bottom-20">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <div className="single-property-info">
                      <h5>Bedrooms</h5>
                      <p><i className="fa fa-bed" />{selectedUnit.node.bedrooms}</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="single-property-info">
                      <h5>Bathrooms</h5>
                      <p><i className="fa fa-bath" />{selectedUnit.node.bathrooms}</p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <div className="single-property-info">
                      <h5>Area</h5>
                      <p><img src={ sqftIcon }alt="sqft icon" />{selectedUnit.node.sqft} sq. ft.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="property-news-single-card border-bottom-yellow padding-top-20">
                <h4>Amenities</h4>
                <div className="row">
                  {
                    selectedUnit.amenities.map((amenity, i) => 
                      <div key={i} className="col-sm-4 mb-2">
                        <ul className="rld-list-style mb-3 mb-sm-0">
                          <li><i className="fa fa-check margin-top-10" />{amenity}</li>
                        </ul>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray">
          <div className="container">
            <div className="row pd-top-60">
              <div className="col-lg-9">
                <div className="property-news-single-card border-bottom-yellow">
                  <h4>{selectedUnit.floorPlan.node.type}</h4>
                  <div className="thumb" style={{textAlign: "center"}}>
                    <img src={floorPlanPic} alt="floorplan" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="container">
            <div className="row pd-top-60">
              <div className="col-lg-9">
                <div className="property-news-single-card container">
                  <h4>Location</h4><br />
                  <MapContainer lat={location.lat} lng={location.lng} name={location.name} width="10" height="100"/>
                  <br />
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default PropertyDetails;